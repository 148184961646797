// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infobar-container {
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  height: 50px;
  margin-top: 30px;
}

.infobar-title {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.infobar-content {
  white-space: pre-wrap; /* Preserve whitespace and wrap text */
  word-wrap: break-word; /* Break long words if necessary */
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/components/infobar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB,EAAE,sCAAsC;EAC7D,qBAAqB,EAAE,kCAAkC;EACzD,WAAW;AACb","sourcesContent":[".infobar-container {\n  border-radius: 5px;\n  overflow: hidden;\n  text-align: center;\n  height: 50px;\n  margin-top: 30px;\n}\n\n.infobar-title {\n  font-weight: bold;\n  margin-bottom: 5px;\n  text-align: center;\n}\n\n.infobar-content {\n  white-space: pre-wrap; /* Preserve whitespace and wrap text */\n  word-wrap: break-word; /* Break long words if necessary */\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
