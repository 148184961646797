// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  align-items: center;
  top: 0;
  width: 100%;
  background-color: #333;
  overflow: hidden;
  z-index: 1000; /* Ensure it stays on top of other content */
}

.navbar-brand {
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  font-size: 18px;
}

.navbar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100;
  justify-content: space-between;
}

.nav-link {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  font-size: 18px;
}

.nav-link:hover {
  background-color: #ddd;
  color: black;
}


.rules-container {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 300px;
  z-index: 1001; /* Ensure it stays on top of the navbar */
}

.navbar-brand:hover .rules-container {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,MAAM;EACN,WAAW;EACX,sBAAsB;EACtB,gBAAgB;EAChB,aAAa,EAAE,4CAA4C;AAC7D;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,SAAS;EACT,UAAU;EACV,aAAa;EACb,UAAU;EACV,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;;AAGA;EACE,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,YAAY;EACZ,aAAa,EAAE,yCAAyC;AAC1D;;AAEA;EACE,cAAc;AAChB","sourcesContent":["body {\n  margin: 0;\n  font-family: Arial, sans-serif;\n}\n\n.navbar {\n  align-items: center;\n  top: 0;\n  width: 100%;\n  background-color: #333;\n  overflow: hidden;\n  z-index: 1000; /* Ensure it stays on top of other content */\n}\n\n.navbar-brand {\n  color: white;\n  text-align: center;\n  padding: 14px 20px;\n  text-decoration: none;\n  font-size: 18px;\n}\n\n.navbar-nav {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  display: flex;\n  width: 100;\n  justify-content: space-between;\n}\n\n.nav-link {\n  display: block;\n  color: white;\n  text-align: center;\n  padding: 14px 20px;\n  text-decoration: none;\n  font-size: 18px;\n}\n\n.nav-link:hover {\n  background-color: #ddd;\n  color: black;\n}\n\n\n.rules-container {\n  display: none;\n  position: absolute;\n  top: 100%;\n  left: 0;\n  width: 300px;\n  z-index: 1001; /* Ensure it stays on top of the navbar */\n}\n\n.navbar-brand:hover .rules-container {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
