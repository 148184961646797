// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chessboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-labels {
  display: flex;
  justify-content: center;
  width: 100%;
}

.column-label, .row-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* Adjust according to your square size */
  height: 50px; /* Adjust according to your square size */
  font-weight: bold;
}

.row {
  display: flex;
}

.row-label {
  width: 50px; /* Adjust according to your square size */
  height: 50px; /* Adjust according to your square size */
  font-weight: bold;
}

.corner {
  width: 50px; /* Adjust according to your square size */
  height: 50px; /* Adjust according to your square size */
}
`, "",{"version":3,"sources":["webpack://./src/components/chessboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW,EAAE,yCAAyC;EACtD,YAAY,EAAE,yCAAyC;EACvD,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW,EAAE,yCAAyC;EACtD,YAAY,EAAE,yCAAyC;EACvD,iBAAiB;AACnB;;AAEA;EACE,WAAW,EAAE,yCAAyC;EACtD,YAAY,EAAE,yCAAyC;AACzD","sourcesContent":[".chessboard-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.column-labels {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.column-label, .row-label {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 50px; /* Adjust according to your square size */\n  height: 50px; /* Adjust according to your square size */\n  font-weight: bold;\n}\n\n.row {\n  display: flex;\n}\n\n.row-label {\n  width: 50px; /* Adjust according to your square size */\n  height: 50px; /* Adjust according to your square size */\n  font-weight: bold;\n}\n\n.corner {\n  width: 50px; /* Adjust according to your square size */\n  height: 50px; /* Adjust according to your square size */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
